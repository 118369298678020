import { SET_DISTRIBUTOR_ORDERS, SET_DISTRIBUTOR_INVOICES } from './constants';

// export const setActionAfterLogIn = (actionType, courseId) => {
//     return {
//         type: SET_LOGIN_ACTION,
//         actionType,
//         payload: courseId
//     };
// };

export const setDistributorOrders = orders => {
  return {
    type: SET_DISTRIBUTOR_ORDERS,
    payload: orders
  };
};

export const setDistributorInvoices = invoices => {
  return {
    type: SET_DISTRIBUTOR_INVOICES,
    payload: invoices
  };
};
