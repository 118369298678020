import React, { useEffect, useState } from 'react';
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import DownloadReport from '../../../dashboard-360/views/DashboardView/DownloadReport'
import {
    Grid,
    makeStyles,
    Card,
    CardContent,
} from '@material-ui/core';

import { grey } from '@material-ui/core/colors';
import { toast } from 'react-toastify'
import 'bootstrap/dist/css/bootstrap.css'
import { DataGrid } from '@material-ui/data-grid';
import Popup from './PopUp'
import { GET_PROFILES, SHORTLIST, REJECT, HIRED, MAKE_LOG, SHOW_PROFILE, URL, GET_HIRES } from './constants'
import ExcelReport from './excelReport'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex'
    },
    grey: {
        color: theme.palette.getContrastText(grey[50]),
        backgroundColor: grey[50]
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    paper: {
        textAlign: 'center'
    },
    list: {},
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginRight: '0.5vw'
    },
    listItem: {
        padding: 0
    },
    cardcontent: {
        padding: '0 0 0 5%',
        '&:last-child': {
            paddingBottom: 0
        }
    },
    formControl: {
        minWidth: 100,
    },
}));

toast.configure()



const Inbound = () => {
    const [profiles, setProfiles] = useState([])
    const [profiles1, setProfiles1] = useState([])
    const [candidate, setCandidate] = useState("")
    const [show, setShow] = useState(false)
    const [link, setLink] = useState()
    const [filter, setFilter] = useState("")
    const [shortlisted, setShortlisted] = useState(0)
    const [rejected, setRejected] = useState(0)
    const [pending, setPending] = useState(0)
    const [search, setSearch] = useState("")
    const [progress, setProgress] = useState(false);
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [resume, setResume] = useState("")
    const [selectedCandaidate, setSelectedCandidate] = useState([])
    const [show1, setShow1] = useState(false)
    const [reason, setReason] = useState("")
    const [rejectId, setRejectID] = useState("")
    const [profileCount, setProfileCount] = useState("")
    const [header, setHeader] = useState("All Profiles")
    const [hired, setHired] = useState(0)
    const [Discarded, setDiscarded] = useState(0)
    const [but, setBut] = useState(false)

    var url = URL

    const classes = useStyles();

    let shortlisted1 = ""
    let rejected1 = ""

    const profilesColumns = [
        {
            headerName: 'First Name',
            field: 'First_Name',
            flex: 1

        },
        {
            headerName: 'Last Name',
            field: 'Last_Name',
            flex: 1
        },
        {
            headerName: 'Job Code',
            field: 'Process_Selected_for',
            flex: 1
        },
        {
            headerName: 'Mobile Number',
            field: 'Mobile_Number',
            flex: 1
        },
        {
            headerName: 'Marital Status',
            field: 'Maritial_Status',
            flex: 1
        },
        {
            headerName: 'Gender',
            field: 'Sex',
            flex: 1
        },
        {
            headerName: 'Email Id',
            field: 'EmailID',
            flex: 1
        },
        {
            headerName: 'Candidate Type',
            field: 'placement',
            flex: 1
        }
    ];


    console.log(profiles)

    //getALl hires
    const getAllhires = () => {
        axios.get(`${GET_HIRES}`)
            .then((response) => {
                console.log(response)
                response.data = response.data.reverse()
                var i = 0
                response.data.map((ele) => {
                    i = i + 1
                    return ele.id = i
                })
                if (localStorage.getItem('role') === 'recruiter') {
                    const recruiterProfiles = response.data.filter((ele) => {
                        return ele.placement === 'Fresher'
                    })
                    setProfiles(recruiterProfiles)
                } else {
                    setProfiles(response.data)
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleClose = () => {
        setLink()
        setShow(false)
    }

    const showProfile = (data) => {
        console.log(data.row.id)
        const result = profiles.filter((ele) => {
            console.log(ele._id)
            return ele._id === data.row._id
        })
        console.log(result)
        setCandidate(result[0])
        setShow(true)
    }

    console.log(candidate)


    useEffect(() => {

        getAllhires()

    }, [])


    return (
        <>
            <br />
            <Grid container spacing={3} direction="row">


                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={12} sm={12} lg={11}> </Grid>
                                <Grid item xs={12} sm={12} lg={1}>  {profiles && profiles.length > 0 && localStorage.getItem('role') === 'Super Admin' && (
                                    <ExcelReport
                                        data={profiles}
                                        fileName={'All Profiles'}
                                    />
                                )}</Grid>
                                <Grid item xs={12} sm={12}>
                                    <div style={{ height: 500, width: '100%' }}>
                                        <DataGrid rows={profiles} columns={profilesColumns} pageSize={20}
                                            // rowsPerPageOptions={[10, 20, 50]}
                                            pagination onRowClick={showProfile} />
                                    </div>
                                </Grid>
                            </Grid>


                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
            <Popup candidate={candidate} show={show} link={link} handleClose={handleClose} />

        </>
    );
};
export default Inbound;


