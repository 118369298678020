import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import {
    Avatar,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    CardHeader,
    Box,
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import Timeline from './timeline'
import axios from 'axios'
import { URL1 } from './constants'


const Popup = (props) => {
    const { candidate, handleClose, show, link, resume } = props
    //console.log(candidate)
    //console.log(resume)

    var url = URL1


    // const Applied = {
    //     action: "Applied",
    //     created_At: candidate.created_At,
    //     userName: candidate.firstName
    // }



    useEffect(() => {

    }, [])


    return (<div>
        <Dialog
            open={show}
            onClose={() => handleClose()}
            style={{ padding: 2 }}
            fullWidth={true}
        >
            <DialogTitle>{candidate.First_Name} {candidate.Last_Name}</DialogTitle>
            < DialogContent>
                <Grid container spacing={3} direction="row">
                    <Grid item xs={12} sm={6}>
                        <p><b>First Name : </b>{candidate.First_Name}</p>
                        <p> <b>Last Name: </b>{candidate.Last_Name}</p>
                        <p> <b>Role : </b>{candidate.Process_Selected_for}</p>
                        <p><b>Email : </b>{candidate.EmailID}</p>
                        <p><b>DOB</b> :{candidate.DOB}</p>
                        <p><b>Gender: </b>{candidate.Sex}</p>
                        <p><b>Marital Status : </b>{candidate.Maritial_Status}</p>
                        <p><b>Nationality : </b>{candidate.Nationality}</p>
                        <p><b>Mobile Number : </b>{candidate.Mobile_Number}</p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><b>Present Residential Address : </b>{candidate.Residential_Address}</p>
                        <p><b>Permanent Address: </b>{candidate.Permanent_Address}</p>
                        <p><b> Father's Name : </b>{candidate.Father_Name} </p>
                        <p><b> Father's Dob : </b>{candidate.Fathers_DOB} </p>
                        <p><b> Mother's Name : </b>{candidate.Mothers_Name} </p>
                        <p><b> Mother's Dob : </b>{candidate.Mothers_DOB} </p>
                        <p><b>Alternate Number : </b>{candidate.Alternate_Number}</p>
                    </Grid>
                    {
                        candidate.placement != 'Fresher' &&
                        <Grid item xs={12} sm={12}>
                            <p><b> Bank Account Number for Salary Credit : </b>{candidate.Bank_Account_Number_for_Salary_Credit} </p>
                            <p><b> IFSC Code : </b>{candidate.IFSC_Code} </p>
                            <p><b> Bank Name : </b>{candidate.Bank_Name} </p>
                            <p><b>Pan Number : </b>{candidate.Pan_Number}</p>
                            <p><b>Adhar Number : </b>{candidate.Adhaar_Number} </p>
                            <p><b> PF Number : </b>{candidate.PF_Number} </p>
                            <p><b> UAN Number : </b>{candidate.UAN_Number} </p>
                        </Grid>
                    }


                    <Grid item xs={12} sm={12}>
                        <p><b> Emergency Contact Number : </b>{candidate.Emergency_Contact_Number} </p>
                        <p><b> Emergency Contact Relationship : </b>{candidate.Emergency_Contact_Relationship} </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p><b>Resume</b> : <a href={URL1 + candidate.resume} target="_blank" rel="noopener noreferrer">View</a></p>
                        <p><b>Photograph</b> :  <a href={URL1 + candidate.photo} target="_blank" rel="noopener noreferrer">View</a></p>
                        <p><b>Adhar Card</b> :  <a href={URL1 + candidate.cheque} target="_blank" rel="noopener noreferrer">View</a></p>
                        {
                            candidate.placement != 'Fresher' && <> <p><b>Pan Card</b> :  <a href={URL1 + candidate.pancard} target="_blank" rel="noopener noreferrer">View</a></p>
                                <p><b>SSLC /PUC/Degree marks card</b> : <a href={URL1 + candidate.sslcMarksCard} target="_blank" rel="noopener noreferrer">View</a></p>
                                <p><b>payslip1</b> : <a href={URL1 + candidate.payslip1} target="_blank" rel="noopener noreferrer">View</a></p>
                                <p><b>payslip2</b> :  <a href={URL1 + candidate.payslip2} target="_blank" rel="noopener noreferrer">View</a></p>
                                <p><b>payslip3</b> :  <a href={URL1 + candidate.payslip3} target="_blank" rel="noopener noreferrer">View</a></p>
                                <p><b>Reliving letter</b> :  <a href={URL1 + candidate.reliving} target="_blank" rel="noopener noreferrer">View</a></p>
                                <p><b>Cancelled Cheque</b> :  <a href={URL1 + candidate.adhar} target="_blank" rel="noopener noreferrer">View</a></p> </>
                        }


                        <p>{link}</p>
                    </Grid>
                </Grid>

                <p><Button variant="contained" onClick={handleClose}>Close</Button></p>
            </DialogContent>
        </Dialog>
    </div >)
}

export default Popup



