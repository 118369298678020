import Login from '../views/Login';

export default [
  {
    path: '/login',
    component: Login,
    key: 'login'
  }
];

