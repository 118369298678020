export const GET_DEALER_PROFILE = 'http://14.98.23.204/boapi/profileID';
export const GET_DEALER_JOINING_DETAILS = 'http://14.98.23.204/boapi/distjoining';
export const GET_DEALER_ORDERS = 'http://14.98.23.204/boapi/orders';
export const GET_DEALER_SINGLE_ORDERS = 'http://14.98.23.204/boapi/order';
export const GET_DEALER_INVOICES = 'http://14.98.23.204/boapi/invoice';
export const GET_DEALER_KYC = 'http://14.98.23.204/boapi/kycstatus';
export const PUT_BREAK_AGENT = 'http://106.51.86.75:42004/crm/agentbreakservices';
export const GET_INTERACTION_BY_DISTRIBUTOR_ID = 'http://106.51.86.75:42004/crm/interactions/getByDistributerID?distributerID=';
export const GET_INTERACTION_BY_AGENT_SIP_ID = 'http://106.51.86.75:42004/crm/interactions/getByAgentSIPID?SipID=';
export const UPDATE_CURRENT_STATUS = 'http://106.51.86.75:42004/crm/currentstatuses/';
export const GET_CURRENT_STATUS_BY_AGENT_SIP_ID = 'http://106.51.86.75:42004/crm/currentstatuses/agentSipID?agentSipID=';
export const ORIGINATE_CALL_WITH_SIP_ID = 'http://106.51.86.75:42004/ami/actions/orginatecall?';
export const UPDATE_CALL_STATUS = 'http://106.51.86.75:42004/crm/interactions/';
export const GET_INBOUND_DASHBOARD_DATA = '/gpreport/service/dashboardcount?AccessKeys=123';
export const GET_INTERACTION_BY_CALLER_NUMBER = 'http://106.51.86.75:42004/crm/interactions/getTransferedData?callerNumber=';
export const SOCKETENDPOINT1 = 'http://106.51.86.75:42001';
export const SOCKETENDPOINT2 = 'http://106.51.86.75:42002';
export const SOCKETENDPOINT3 = 'http://106.51.86.75:42003';
export const SOCKETENDPOINT4 = 'http://106.51.86.75:42005';
export const Agent_service_url = 'http://106.51.86.75:42004';
export const UPDATE_CALL_STATUS_336 = 'http://106.51.86.75:42004/crm/interactions/'
export const SOCKETENDPOINT5 = 'http://106.51.86.75:62002';